import React, { useEffect, useRef } from "react"
import { navigate, Link } from "gatsby"
import { getApiEndpoint, isEDMode, getNested, isEvokeScreenshooting } from '@src/helper';
import Layout from '@components/structure/Layout.jsx';
// Utility imports
import SEO from '@components/utility/SEO.jsx';

export const Head = () => (
	<>
        <meta name="robots" content="noindex"></meta>
	</>
)
const UnsubscribeConfirmed = ({location}) => {
    
    if (isEDMode || isEvokeScreenshooting()) {
        location.state = {
            values: {
                email: [<span className="variable-content">name@domain.com</span>],
                unsubscribeTreatment: true,
                unsubscribeDiseaseAwareness: true,
                unsubscribeCorporate: true
            } 
        }
    }
    const locationState = useRef(location?.state?.values)
    useEffect(()=>{
        //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect
        if(!isEDMode && !isEvokeScreenshooting() && !getNested(location,'state', 'values', 'email')) {
            navigate('/unsubscribe');
        }
    },[])

    const resubscribe = (e) => {
        if(e.type === 'click' || e.key === 'Enter'){
            e.preventDefault()
            const unSubToken = location.state.token ? {token: location.state.token} : {}
            fetch(getApiEndpoint() + 'psp/resubscribe', {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(unSubToken),
            })
            .then(async (resp) => {
                var r = await resp.json();
                if (r.success) {
                    navigate('/resubscribe-confirmation',
                        {
                            state: {
                                values: location.state.values 
                            }  
                        }
                    );
                } else {
                    navigate('/500');
                }
            })
            .catch((err) => {
                navigate('/500');
            })
        }
    }

    return (
        <Layout pageClassName="page-unsubscribe-confirmation">
		<SEO title='You Have Successfully Unsubscribed | Aurinia Alliance&reg;' description='' />
		<div className="wrapper unsubscribe-confirmation-content">
            <h2 className="blue">You've unsubscribed</h2> 
            <p>{locationState?.current?.email}, you've chosen to stop receiving marketing communications from Aurinia Alliance.</p>
            {(locationState?.current?.unsubscribeTreatment || locationState?.current?.unsubscribeDiseaseAwareness || locationState?.current?.unsubscribeCorporate) && <p>You have also chosen to unsubscribe from the following:</p>}
            <ul>
                {locationState?.current?.unsubscribeTreatment && 
                    <li>Information about treatment for lupus nephritis</li>
                }
                {locationState?.current?.unsubscribeDiseaseAwareness && 
                    <li>Disease state awareness information</li>
                }
                {locationState?.current?.unsubscribeCorporate && 
                    <li>Corporate communications from Aurinia</li>
                }
            </ul>
            <p>If you've changed your mind, you can <a className="blue-cta" tabIndex="0" onClick={resubscribe} onKeyDown={resubscribe}>resubscribe here</a>. You can always come back later to resubscribe.</p>
            <Link to="/" className="btn btn--purple">RETURN TO HOME</Link>
		</div>
		
	</Layout>

    )
}

export default UnsubscribeConfirmed
